.speaker-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;

    box-sizing: border-box;
    width: 310px;
    min-height: 410px;
    margin: 40px;
    cursor: pointer;
    background: #FFFFFF;
    border: 2px solid #000000;
    box-shadow: 10px 6px 5px 0px #00000040;

    border-radius: 12px; 
    padding: 12px;
}

.speaker-image{
    box-sizing: border-box;
    width: 172px;
    height: 172px;
    margin: 5px;
}

.speaker-image img{
    max-width: inherit;
    max-height: inherit;
    width: inherit;
    height: inherit;
    object-fit: cover;
    border: 5px solid #FFCA41;
    border-radius: 50px;
}

.speaker-name{
    overflow-wrap: break-word;
    width: 80%;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 44px;
    text-align: center;
    margin: 2px;
    color: #252525;
}

.speaker-position{
    word-wrap: break-word;
    overflow-wrap: break-word;
    display: inline-block;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    background: #2480F0;
    border-radius: 5px;
    padding: 8px;
    color: #FFFFFF;
}

.speaker-company{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin: 2px;
    color: #7E7E7E;
}



