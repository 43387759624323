.navbar {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.20);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.Nav {
  height: 55px;
  width: 100%;
  background-color: #43a6f0;
  display: flex;
  justify-content: right;
  justify-items: center;
  align-items: center;
}

.Nav a {
  color: white;
  text-decoration: none;
  cursor: pointer;
  padding: 0.25rem 20px;
  transition: background-color 0.3s ease, padding 0s;
  display: flex;
  align-items: center;
  transition: none;
}

.Nav li {
  margin-right: -0.25rem;
  margin-left: -0rem;
  list-style: none;
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, padding 0s;
}

.Nav li:hover {
  color: darkgray;
  background-color: #238cbf;
}
.Nav li.active {
  background-color: #124b67;
}

.Nav ul {
  list-style: none;
  display: flex;
  /* gap: 1rem; */
  align-items: start;
  height: 100%;
  margin: 0;
}

.hamburger {
  display: none;
}

.hamburger Button {
  padding: 1rem;
}

.hamburger-list {
  gap: 0.5rem;
  font-size: 0.8em;
  width: 100%;
  padding-inline-start: 0px;
  list-style: none;
  background-color: #43a6f0;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.hamburger-list li {
  width: 100%;
  padding-left: 1rem;
  margin: 0.25rem;
}
.hamburger-list li:hover {
  cursor: pointer;
}

.hamburger-list .hamburger {
  display: block;
}

.navbar-alternate .Nav{
  background-color: white;
}

.navbar-alternate a{
  color: #3779ac; 
}

.navbar-alternate li.active{
  background-color: #dfdfdf; 
}
.navbar-alternate li:hover{
  background-color: #dfdfdf; 
}
.navbar-alternate .hamburger-list{
  background-color: white;
}
.navbar-alternate .hamburger-list li{
  background-color: white;
}


/* 855 px */
@media screen and (max-width: 855px) {
  /* HAMBURGER */
  .hamburger {
    display: block;
    margin-right: auto;
  }

  .Nav ul {
    display: none;
  }

}

@media screen and (min-width: 855px) {
  .hamburger-list {
    display: none;
  }
}