#background-img {
  width: 90%;
  height: 100%;
}

.heading-div {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 0;
}
.heading-div h1 {
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
}
.title-div h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.5vw;
  margin-top: 0;
}

.title-div p {
  font-family: "Open Sans", sans-serif;
  width: 28vw;
  font-size: 1vw;
}

#globe-gif {
  max-width: 10vw;
  width: 3vw;
  height: 90%;
  margin-left: -5px;
  margin-bottom: 3px;
}
.btn{
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
} 
.btn:hover .btn-slide-show-text1 {
  margin-left: 65px;
} 

.tickets-btn {
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #2480f0, #00dba2, #54a7ed, #54a7ed); 
  height: 2.8rem;
  box-shadow: 0px 7px 4px 0px #00000029;
  margin-top: 30px;
  color: white;
  font-weight: 600;
  font-size: large;
  margin-top: 30px;
  cursor: pointer;
  background-size: 300% 100%;
  border-radius: 30px;
}
.tickets-btn-active{
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #7e5bd6, #cf639c, #d29c59);
  height: 10%;
  box-shadow: 5px 7px 4px 0px #00000026;
  border-radius: 30px;
  margin-top: 30px;
  color: white;
  font-weight: 500;
  font-size: large;

}

.tickets-btn:hover {
  background-image: linear-gradient(to right, #2480f0, #00dba2, #54a7ed, #54a7ed); 
  transition: all 0.5s ease-in-out 0s;
  background-position: 100% 0px;
} 


@media (max-width: 1024px) {
  #globe-gif {
    width: 3rem;
    height: 10%;
    margin-bottom: -2px;
  }
}


/* in between transition, dont remove this media query because its essential for them not to overlap*/
@media (min-width: 1024px) and (max-width: 1312px) {
  #background-img {
    width: 100%;
    height: 90%;
  }
}
