.organizer-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-self: center;
    justify-self: center;

    box-sizing: border-box;
    width: 234px;
    min-height: 355px;
    background-color: #FFFFFF;
    margin: 32px;

    border: 2px solid #000000;
    border-radius: 19px;
    box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, .5);
}

.organizer-image{
    justify-self: flex-start;
    box-sizing: border-box;
    width: 203px;
    height: 225px;
    margin: 16px;
}

.organizer-image img{
    max-width: inherit;
    max-height: inherit;
    width: inherit;
    height: inherit;
    object-fit: cover;
    border: 1.75px solid #000000;
    border-radius: 6px;
}

.organizer-name{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 5px;
    margin-bottom: 2px;
    font-weight: 700;

    color: #000000;
}

.organizer-position{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 21px;

    color: #000000;
}