@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
.organizers-div {
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding-bottom:150px;
    padding-top: 30px;
    padding-left: 100px;
    padding-right: 100px;
    position: relative;
    z-index: 10;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.148);
}


.organizers-cards-container{
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;

}

@media only screen and (max-width: 1100px){
    .organizers-cards-container{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 750px){
    .organizers-cards-container{
        grid-template-columns: 1fr;
    }
}