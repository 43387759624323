.sessions-div {
  width: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0px;
  padding: 20px;
  padding-bottom: 90px;
  align-items: center;
  position: relative;
}

.tabs-container {
  display: flex;
  max-width: 1000px; 
  width: 99%; 
}

.tab {
  width: 1000px;
  cursor: pointer;
  background-color: #ffffff;
  border: 2px solid #000000;
  border-bottom: none;
text-align: center;
  padding: 20px 15px;
  margin-right: 0px;
  transition: background-color 0.5s;
}

.tab:first-child{
  border-top-left-radius: 20px; 
 border-right: none;
}

.tab:nth-child(2){
  border-top-right-radius: 20px; 
  border-left: none;
}

.tab span {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  font-weight: 500;
  transition: transform 0.3s; 
}

.tab.active {
  background-color: #2480f0; 
  color: white;
  
}

.tab.active span {
  transform: scale(1.1); 
}

.session-cards-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  border: 2px solid black;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  max-width: 1000px; 
  width: 99%; 
  margin: 0 auto;
  background-color: #E4F2FF; 
}

.sessions-row {
  display: flex;
  flex-direction: column; 
  align-items: center;
  gap: 20px;
}

.sessions-row .session-card {
 width: 90%;
}

.session-cards-container .sessions-row:first-child .session-card {
  margin-top: 50px; 
}

.session-cards-container .sessions-row:last-child .session-card {
  margin-bottom: 50px; 
}

@media (max-width: 450px) {
  .tab span {
    font-size: 12px;
  }
}