.app-section {
  width: 100%;
}

.attendee-div {
  background-color: rgb(241 245 249);
  overflow: hidden;
  padding: 0;
  position: relative;
  height: 400px; 
  border-radius: 10px;
  z-index: 11;
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.148);
}



.attendee-div:before,
.attendee-div:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.attendee-div:before {
  left: 0;
  background: linear-gradient(to left, rgba(00, 255, 255, 0), rgb(255, 255, 255)); 
}

.attendee-div:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255)); 
}

.marquee-container {
  display: flex;
  align-items: center;
  gap: 80px; 
  padding: 15px;
}

.attendee-image-wrapper {
  margin-right: 80px; 
}


.attendee-image {
  /* height: 100px;  */
  width: auto;
  transition: transform 0.30s ease;
}

.attendee-image:hover {
  transform: translateY(-5px); 
}
