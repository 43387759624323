.section-title {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 152px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 400;
  font-size: 50px;
  line-height: 95.43px;
  color: #2480f0;
}

.section-title-2{
  text-align: center;
  width: 100%;
  height: 100px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 400;
  font-size: 50px;
  line-height: 95.43px;
  color: #2480f0;
}