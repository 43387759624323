.App {
  height: 100vh; 
  overflow-y: auto;
  width: 100%; 
}

/* Global Section style */
/* Is critical to highlight current */
/* app-section in Nav */
.app-section{
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  /* box-shadow: 0px 5px 10px rgb(164, 164, 164); */
}