.location-div {
    width: 100%;
    background-color: rgb(241 245 249);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.148);
}

.text-column{
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    justify-content: center;
}

.text-header{
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 0px;
}

.text-description{
    font-size: 16px;
    font-weight: 100;
    margin-top: 0px;
}