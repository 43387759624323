.session-card {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  align-items: center;
  border: 2px solid #000000;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 10px;
  max-width: 1000px;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.imageContainer {
  flex: 0 0 auto;
  margin-right: 20px;
  width: 100px;
  height: 100px;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  border-radius: 20%;
  object-fit: cover;
}

.imageContainer img.with-border {
  border: 4px solid #2480F0;
}

.infoContainer {
  flex: 1;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #252525;
}

.speaker {
  margin-bottom: 20px;
  color: #7E7E7E;
}

.time {
  font-size: 14px;
  color: #7E7E7E;
}

.session-card .time span {
  font-weight: bold;
  font-size: 16px;
  color: #2480F0;
}

.arrowContainer {
  flex: 0 0 auto;
  margin-left: 12px;
}

.material-symbols-outlined {
  font-size: 50px;
  color: #7E7E7E;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.description {
  margin-top: 20px;
  border-top: 1px solid #ccc;
 padding: 20px 20px 0 20px;
  overflow: hidden;
}

.session-card.expanded .description-container {
  max-height: 1000px;
}

.rotate {
  transform: rotate(180deg);
}


/*For Panel Card*/
.moderator {
  color: #7E7E7E;
}
.panelists {
  margin-bottom: 20px;
  color: #7E7E7E;
}


@media (max-width: 768px) {
  .imageContainer {
    display: none;
  }
  .arrowContainer {
    flex: 1 1 100%;
    margin-left: 0px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .material-symbols-outlined
  {
    font-size: 30px !important;
}
}
