#devteam-title{
    color: white;
}
.devTeam-div {
    width: 100%;
    background-color: #cae6ff;
    padding: 20px;
    padding-top: 30px;
    background: linear-gradient(315deg, #03e5b7 10%, #54a7ed 74%); 
    position: relative;
}

.devTeam-text {
    width: 100%;
    height: 100px;
    background-color: lightsteelblue;
    text-align: center;
    font-family: 'Rock Salt', cursive;
    font-weight: 400;
    font-size: 40px;
    line-height: 95.43px;
}

.devTeam-cards-container{
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width:1400px){
    .devTeam-cards-container{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (max-width:1100px){
    .devTeam-cards-container{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width:750px){
    .devTeam-cards-container{
        grid-template-columns: 1fr 1fr;
    }
}