#speaker-title{
    color: white;
}
.speakers-div {
    width:100%;
    background: linear-gradient(315deg, #03e5b7 10%, #54a7ed 74%); 
    padding: 20px;
    padding-top: 30px;
    position: relative;
    z-index: 12;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.148);
}


.speaker-cards-container {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;    
    justify-content: center;
    align-items: center;

} 

@media only screen and (max-width:1600px) {
    .speaker-cards-container {
        grid-template-columns: 1fr 1fr 1fr;
    }

}

@media only screen and (max-width:1200px) {
    .speaker-cards-container {
        grid-template-columns: 1fr 1fr;
    }

}

@media only screen and (max-width:780px) {
    .speaker-cards-container {
        grid-template-columns: 1fr;
    }

}