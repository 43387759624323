.speaker-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .modal-image{
    box-sizing: border-box;
    width: 300px;
    height: 300px;
    margin: 5px;
}

.modal-image img{
    max-width: inherit;
    max-height: inherit;
    width: inherit;
    height: inherit;
    object-fit: cover;
    border: 5px solid #FFCA41;
    border-radius: 35px;
}
  
  .speaker-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 800px;
    overflow: auto;
    position: relative;
    padding: 50px
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 800;
    padding-left: 7px;
  }

  .modal-content{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .modal-header h2 {
    margin: 0;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size:36px;
    color: #999;
  }
  .speaker-description {
    display: flex;
    width: 100%; 
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #1C5294;
    margin-right: 35px;
    padding-right: 20px;
    overflow-y: auto; 
    height: 270px;
  }

  .bottom-right-image {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 20px;
    width: 125px;
    height: auto;
  }

  @media (max-width: 660px) {
    .modal-content{
        display: flex;
       flex-direction: column;
       align-items: center;
      }

      .speaker-modal {
        width: 350px;
      }

  .modal-header {
    padding-left: none;
  }

      .bottom-right-image {
        display: none;
      }

      .speaker-description {
        margin-right: 0px;
        margin-top: 0px;
        height: 200px;
      }

      .modal-image{
     
        width: 200px;
        height: 200px;
  
    }
}